@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#__next {
  max-width: 100vw;
  @apply font-body;
  @apply dark:bg-gray-900;
  @apply dark:text-white;
}


.slick-track {
  margin-left: 0 !important;
}