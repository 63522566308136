.label {
    @apply relative;
    border-width: 1px;
}

.label:after {
    position: absolute;
    content: '';
    border-width: 5px;
    border-color: #eee transparent transparent transparent;
    @apply border-solid;
    @apply top-8;
    @apply right-10;
}

.select {
    appearance: none;
}

.list-wrapper {
    top: 100%;
    margin-top: 1px;
}

