.wrapper {
    @apply relative;
}

.wrapper:before,
.wrapper:after {
    @apply absolute;
    @apply text-xs;
    display: none;
}

.wrapper:before {
    content: attr(data-label-before);
    @apply left-0;
}

.wrapper:after {
    content: attr(data-label-after);
    @apply right-0;
}

.marker {
    @apply relative;
}
.marker:after {
    @apply absolute;
    width: 3px;
    height: 3px;
    @apply bg-gray-300;
    @apply opacity-80; 
    @apply rounded-full;
    left: 50%;
    @apply top-9;
    margin-left: -2px;
    content: '';
}

.marker:last-of-type:after {
    left: initial;
    right: 0;
}