.wrapper{
    z-index: 99;
    background-color: rgba(0,0,0,0.5);
}

.container {
    @apply w-full;
    @apply h-full;
    @apply lg:w-10/12;
    @apply lg:h-4/6;
    @apply bg-transparent;
}